.move-motion(@className, @keyframeName) {
  @name: ~'@{dev-prefix}-@{className}';
  .make-motion(@name, @keyframeName);
  .@{name}-enter,
  .@{name}-appear {
    opacity: 0;
    animation-timing-function: @ease-out-circ;
  }
  .@{name}-leave {
    animation-timing-function: @ease-in-circ;
  }
}

.move-motion(move-up, antMoveUp);
.move-motion(move-down, antMoveDown);
.move-motion(move-left, antMoveLeft);
.move-motion(move-right, antMoveRight);

@keyframes antMoveDownIn {
  0% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}

@keyframes antMoveDownOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }

  100% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}

@keyframes antMoveLeftIn {
  0% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}

@keyframes antMoveLeftOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }

  100% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}

@keyframes antMoveRightIn {
  0% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}

@keyframes antMoveRightOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }

  100% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}

@keyframes antMoveUpIn {
  0% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}

@keyframes antMoveUpOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }

  100% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
