.list-icon,
.pic-icon {
	display: block;
	margin-right: 10px;
	margin-bottom: 20px;
	width: 24px;
	height: 24px;
	background: #999;
	mask-size: cover;
	mask-repeat: no-repeat;
	cursor: pointer;
	&:hover,
	&.active {
		background: #26ad67;
	}
}
.list-icon {
	mask-image: url("../images/list.svg");
}
.pic-icon {
	mask-image: url("../images/pic.svg");
}

.sku-tag {
	line-height: 22px;
	height: 22px;
	padding: 2px 10px;
	margin: 0 8px;
	font-size: 14px;
	font-weight: 400;
	color: #666;
	background: #f4f4f4;
	white-space: nowrap;

	&.available {
		background: #eaf8ee;
		color: #36b457;
	}

	& + .sku-tag {
		margin: 0;
	}
}

.original-name {
	line-height: 20px;
	height: 20px;
	font-size: 14px;
	font-weight: 400;
	color: #000;
	opacity: 0.5;
}

.sku-id {
	line-height: 22px;
	height: 22px;
	margin-bottom: 24px;
	font-size: 16px;
	font-weight: 400;
	color: #000;
	opacity: 0.85;

	span {
		font-weight: 600;
	}
}

.sku-info {
	display: flex;
	align-items: center;
	span {
		flex: 1;
	}
}

.sku-list {
	display: flex;
	width: 100%;
	height: 100%;

	.sku-list-left {
		display: flex;
		flex-direction: column;
		padding-right: 20px;
		margin-right: 20px;
		overflow: auto;

		&.drawer {
			max-width: 200px;
			border-right: 1px solid rgba(0, 0, 0, 0.06);
		}

		&.full {
			max-width: 240px;
			padding: 20px;
			border-radius: 8px;
			border: 1px solid #efefef;
		}
	}

	.sku-list-right {
		flex: 1;
		width: 10px;
	}
}
.drawer-left {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 80%;
}
.drawer-right {
	display: flex;
	flex-direction: column;
	width: 20%;
	min-width: 255px;
	height: 100%;
	&.wider {
		width: 100%;
		.select-commodity-list {
			border-left: 0px;
		}
	}
	.drawer-right-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 46px;
		line-height: 46px;
		width: 100%;
		flex: none;
		color: #36b457;
		font-size: 14px;
		border-bottom: 1px solid #f0f0f0;
		.commodity-btn {
			margin-right: 4px;
		}
	}
	.select-commodity-list {
		display: flex;
		flex-direction: column;
		flex: 1;
		width: 100%;
		height: 10px;
		padding: 10px 5px;
		border-left: 1px solid #f0f0f0;
		overflow: auto;
		.select-commodity-item {
			display: flex;
			align-items: center;
			width: 100%;
			padding: 8px;
			margin-bottom: 10px;
			border: 1px solid #f0f0f0;
			border-radius: 4px;
			cursor: pointer;
			&:hover {
				box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
			}
			.item-img {
				width: 40px;
				height: 40px;
				flex: none;
			}
			.item-info {
				flex: 1;
				width: 10px;
				margin-left: 4px;
				font-size: 12px;

				.info-name,
				.info-brand {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
				.info-brand {
					margin-top: 4px;
					font-size: 10px;
				}
			}
			.item-del {
				flex: none;
				width: 20px;
				margin-left: 4px;
			}
		}
	}
}

@primary-color: #35b558;