ul {
  padding: 0;
  margin: 0;
}
.pic-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  .list-item {
    width: 223px;
    margin-top: 20px;
    margin-right: 10px;
    background: #fff;
    border: 1px solid #eee;
    list-style: none;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      .list-item-top {
        .operate-wrapper {
          display: block;
        }
      }
    }
    .list-item-top {
      position: relative;
      padding: 8px 8px 0;
      .operate-wrapper {
        position: absolute;
        top: 16px;
        right: 16px;
      }
      .operate-icon {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.25);
        cursor: pointer;
        &:hover {
          background: rgba(0, 0, 0, 0.3);
        }
      }
    }
    .list-item-bottom {
      padding: 15px 8px;
      span {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        font-weight: 600;
        height: 40px;
        line-height: 20px;
        font-size: 14px;
        color: #111;
      }
    }
  }
}
.pic-list-pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@primary-color: #35b558;