@import "@style/variable.less";

.page-basic-layout {
  min-width: 1200px;
  height: 100vh;
  display: flex;
  .page-basic-nav {
    background: #fff;
  }

  .page-basic-body {
    flex: 1;
    background: #fafafa;
    padding: 0 8px;
    display: flex;
    flex-direction: column;

    .page-basic-header{
      height: 54px;
    }

    .page-basic-content{
      flex: 1;
      background-color: #fff;
      // padding: 18px;
      overflow: hidden;
    }
  }
}

.header-logo-container{
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  .header-logo-element{
    width: 138px;
    margin: 0 20px;
  }

  .header-logo-collapse-btn{
    width: 80px;
    font-size: 20px;
    line-height: 54px;
    text-align: center;
    position: relative;
    top: 4px;
    cursor: pointer;
    transition: all .2s ease;
    &:hover {
      color: @primaryColor;
    }
  }
}


.box{
  height: 3000px;
  color: #fff;
  background-color: #333;
}
@primary-color: #35b558;