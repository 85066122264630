@import "@style/variable.less";

.upload-file-single-container{
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color .3s;
  width: 240px;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover{
    border-color: @primaryColor;
  }

  .remove-file{
    position: absolute;
    top: 8px;
    right: 8px;
  }
}
@primary-color: #35b558;