// Config global less under antd
[class^=~"@{dev-prefix}-"],
[class*=~" @{dev-prefix}-"] {
  // remove the clear button of a text input control in IE10+
  &::-ms-clear,
  input::-ms-clear,
  input::-ms-reveal {
    display: none;
  }
}

.user-drag {
  -webkit-user-drag: none;
}

.fixed-right-btn {
  position: fixed;
  bottom: 80px;
  right: 40px;
  z-index: 99;
}
