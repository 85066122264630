.arrCollapse{
    .ant-collapse-item{
      margin-bottom: 20px;
        .ant-collapse-header{
          padding: 10px 0px !important;
        }
        .ant-collapse-content{
          .ant-collapse-content-box{padding: 0px 10px !important;}
        }
}}
@primary-color: #35b558;