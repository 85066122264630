body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.page {
  width: 100%;
  height: 100%;
}

.page-container {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.mb-12 {
  margin-bottom: 12px!important;
}
.mb-20 {
  margin-bottom: 20px!important;
}
.mb-30 {
  margin-bottom: 30px;
}

.mr-12 {
  margin-right: 12px!important;
}
.mr-20 {
  margin-right: 20px!important;
}
.mr-30 {
  margin-right: 30px;
}

#nprogress .bar {
  background: #35b558 !important;
}

.container-580 {
  width: 580px;
  margin: 0 auto;
}
.container-980 {
  width: 980px;
  margin: 0 auto;
}
.container-1180 {
  width: 1180px;
  margin: 0 auto;
}

.container-1200 {
  width: 1200px;
  min-width: 1200px;
  margin: 0 auto;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.disabled {
  pointer-events: none !important;
  cursor: none;
}

.space-wrap{
  white-space: pre-wrap;
}
