.ant-table-cell{
    .divider-btn + .divider-btn{
    &::before{
        color: #f0f0f0;
        cursor: default;
        margin: 0px 7px;
        content: '|';
    }
    }

    .danger-btn{
         color: #ff4d4f ;
         cursor: pointer ;
    }
    .primary-btn{
        color: #35b558; 
        cursor: pointer
    }
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.invalid{
  opacity: 0.25;
}


@primary-color: #35b558;