.basic-tree-layout{
  height: 100%;
  display: flex;

  .basic-tree-layout__left{
    width: 280px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    .basic-tree-header{

    }

    .basic-tree-body{
      flex: 1;
    }

    .basic-tree-footer{

    }
  }

  .tree-item-container{
    display: flex;

    font-size: 14px;
    .tree-item-hd{

    }
    .tree-item-bd{
      flex: 1;
    }
    .tree-item-ft{
      &:hover{
        .anticon{
          background: #e4e4e4;
        }
      }
    }
  }

  .basic-tree-layout__right{
    flex: 1;
  }

  .site-tree-search-value {
    color: #f50;
  }
}
@primary-color: #35b558;