.filter-container {
  margin-bottom: 12px;
}

.filter-category {
  margin-bottom: 8px;
  line-height: 20px;
  font-size: 14px;
}

.filter-attr-list {
  display: flex;
  flex-wrap: wrap;

  .filter-attr-item{
    line-height: 28px;
    height: 28px;
    min-width: 48px;
    margin: 0 4px 8px 0;
    padding: 0 8px;
    font-size: 14px;
    text-align: center;
    border-radius: 14px;
    border: unset;
    color: rgba(9, 18, 12, 0.60);
    background-color: #F4F4F4;
    cursor: pointer;
    white-space: nowrap;

    &.size{
      width: 100%;
    }

    &.ant-tag-green{
      background-color: #E6F9DB;
      color: #3C7D14;
    }
  }
}
@primary-color: #35b558;