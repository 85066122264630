@import "@style/variable.less";

.page-header {
  height: 54px;

  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    min-width: 1200px;
    margin: 0 auto;
    
    .header-hd,
    .header-bd,
    .header-ft {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  .user-container{
    width: 36px;
    height: 36px;
    border-radius: 50%;
    cursor: pointer;
    .user-avatar{
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  .user-overlay-container{
    width: 170px;
    background-color: #fff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.06);

    .user-content{
      padding: 24px 20px;
      line-height: 14px;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      color: #333333;
      border-bottom: 1px solid #e6e6e6;
      .user-name {
        display: inline-block;
        max-width: calc(100% - 28px);
        vertical-align: middle;
        &:after{
          display: inline-block;
          content: "";
          margin-left: 10px;
          width: 18px;
          height: 18px;
          vertical-align: middle;
          background-size: 18px;
        }
      }
    }
  }
}
@primary-color: #35b558;