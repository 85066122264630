@import "@style/variable.less";

.bind-phone-modal{
  padding: 0 20px;
  width: 400px;
  margin: 0 auto;
  .bind-phone-cancel {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    background-size: 30px 30px;
    transition: all .2s ease;
    &:hover {
      cursor: pointer;
      opacity: .8;
    }
  }

  .bind-phone-logo-element {
    width: 100px;
    height: 83px;
    margin: 29px auto;
    background-image: url("../images/homeplan-logo.svg");
    background-size: 100px 83px;
  }

  .modal-title {
    margin-bottom: 22px;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    color: #000;
  }

  .ant-form-item{
    .ant-input {
      background: #f4f4f4!important;
      border-color: #000!important;
      border-radius: 2px !important;
      box-shadow: none!important;
    }
  }

  .form-code-container{
    display: flex;

    .form-code-input{
      flex: 1;
    }
    .form-code-box{
      display: inline-block;
      width: 110px;
      height: 42px;
      line-height: 42px;
      margin-left: 10px;
      font-size: 12px;
      font-weight: 400;
      text-align: center;
      color: #ffffff;
      border-radius: 2px;
      border: 1px solid #000;
      background: @primaryColor;
      outline: none;
      &:hover {
        cursor: pointer;
      }

      &.disable{
        background-color: #999999;
        &:hover {
          cursor: not-allowed;
        }
      }
    }
  }

  .form-submit-btn-container{
    padding: 102px 0 40px;
    text-align: center;
  }

  .form-submit-btn{
    display: inline-block;
    width: 206px;
    height: 46px;
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    line-height: 40px;
    text-align: center;
    background: url("../images/bind-phone-modal-btn__disable.svg") no-repeat center;
    border: none;
    outline: none;
    padding: 0 4px 4px 0;
    &:hover {
      filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.3));
      cursor: not-allowed;
    }
    &.active {
      background: url("../images/bind-phone-modal-btn__active.svg") no-repeat center;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
@primary-color: #35b558;