.basic-upload-preview-list-item{
  position: relative;
  height: 104px;
  text-align: center;

  .upload-video-img{
    width: 100%;
    height: 100%;
  }

  .upload-video-mask{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all .2s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover{
      background-color: rgba(0, 0, 0, .3);
      .upload-video-mask-icon{
        font-size: 18px;
      }
    }
  }

  .upload-video-mask-icon{
    font-size: 0px;
    cursor: pointer;
    color: #fff;

    &:hover{
      opacity: .8;
    }
  }
}
@primary-color: #35b558;